import { Getter } from "vuex";
import { IState, IFilterOption } from './state';

const translation: Getter<IState, IState> = state => state.translation;
const searchHits: Getter<IState, IState> = state => state.searchHits;
const searchInfo: Getter<IState, IState> = state => state.searchInfo;
const app: Getter<IState, IState> = state => state.app;

const urlQuery: Getter<IState, IState> = state => {


	var searchFilters = {};
	for (var key in state.filters) {
		// skip loop if the property is from prototype
		if (!state.filters.hasOwnProperty(key)) continue;

		if(key === 'dates'){
			var dates = state.filters[key];
			if(dates.startdate){
				searchFilters['start'] = dates.startdate;
			}
			if(dates.enddate){
				searchFilters['end'] = dates.enddate;
			}
		} else {
			var filterGroup = state.filters[key];
			filterGroup.forEach(function (filterOption: IFilterOption) {
				if (filterOption.value) {
					if (!searchFilters[key]) {
						searchFilters[key] = filterOption.id;
					} else {
						searchFilters[key] += "," +filterOption.id;
					}
				}
			});
		}
	}

	var basic = { query: state.searchInfo.query, page: state.searchInfo.currentPage}	
	var queryObject = { ...basic, ...searchFilters};
	return queryObject;
};

export default {
	translation,
	searchHits,
	searchInfo,
	app,
	urlQuery
}
