<template>
    <div class="jk-events-listing__form-filter-item">
        <div class="jk-dropdown-filter" v-click-outside="hideDropdown">
            <button class="jk-dropdown-filter__button" type="button" v-on:click="toggleShowDropdown()" aria-controls="jk-event-listing__category-filter" :aria-expanded="showDropdown">
                <span class="material-icons-outlined">local_offer</span> {{getButtonText}}
            </button>
            <div class="jk-dropdown-filter__content" id="jk-event-listing__category-filter" v-show="showDropdown"> 
                <div v-for="filterOption in filters[name]" :key="filterOption.id" class="jk-dropdown-filter__checkbox-container">
                    <input type="checkbox" v-on:change="onFilterChange" :id="filterOptionName(name, filterOption)" :name="filterOptionName(name, filterOption)" aria-controls="jk-events-listing__hit-list" v-model="filterOption.value">
                    <label :for="filterOptionName(name, filterOption)">{{getFilterTitle(name, filterOption)}}</label>
                </div>
            </div>
        </div>
        <div class="jk-dropdown-filter__selected-tags jk-dropdown-filter__selected-tags--min-height">
            <div class="jk-dropdown-filter__selected-tag" v-for="filterOption in getSelectedFilterOptions" :key="filterOption.id">
                {{getFilterTitle(name, filterOption)}} <button type="button" title="Ta bort" v-on:click="unselectFilterOption(filterOption)">&#x2715;</button>
            </div>
        </div>
    </div> 
</template>


<script>
export default ({
    name: 'dropdownFilter',
    props: {
        name: String,
    },
    data: function ()  {
        return {            
            showDropdown: false,
        }
    },
	computed: {
        translation() { return this.$store.getters.translation },
        filters: function(){
            return this.$store.state.filters;
        },        
        filterOptions: function(){
            return this.$store.state.filter_options;
        },
        getButtonText: function(){
            let key = "filter_" +this.name +"_button_text";
            let translation = this.translation[key];
            if(translation){
                return translation;
            }
            return key;
        },
        getSelectedFilterOptions: function(){        
            return this.filters[this.name].filter((filterOption) => { return filterOption.value });         
        }
    },
    methods: {
        toggleShowDropdown:function(){
            this.showDropdown = !this.showDropdown;
        },
        hideDropdown:function(){
            if(this.showDropdown){
                this.showDropdown = false;
            }
        },
        filterOptionName: function(prefix, filterOption){
            return prefix +"-" +filterOption.id;
        },
        getFilterTitle: function(prefix, filterOption){
            return filterOption.title;
        },   
        resetFilter: function() {
            this.$store.commit({
                type: 'resetFilters',
                payload: {}
		    });
            this.updateSearchResults();
        },     
        updateSearchResults: function(){
            this.$store.commit({
                type: 'setSearchInfo',
                payload: { 
                    currentPage: 1,
                    timestamp: Date.now(),
                }
            });
            this.$router.push({query: this.$store.getters.urlQuery});
        },
        onFilterChange: function(){
            this.updateSearchResults();
        },
        unselectFilterOption: function(filterOption){
            filterOption.value = false;
            this.updateSearchResults();
        },        
    }
})
</script>
<style>

</style>