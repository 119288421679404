
import Vue from 'vue';
// import component and stylesheet
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

// see docs for available options
const datepickerOptions = {}

Vue.use(AirbnbStyleDatepicker, {
  sundayFirst: false,
  dateLabelFormat: 'YYYY-MMMM-D',
  days: ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'],
  daysShort: ['Mån', 'Tis', 'Ons', 'TOrs', 'Fre', 'Lör', 'Sön'],
  monthNames: [
    'Januari',
    'Februari',
    'Marr',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December',
  ],
  colors: {
    selected: '#be5840',
    inRange: '#c8705c',
    selectedText: '#fff',
    text: '#565a5c',
    inRangeBorder: '#be5840',
    disabled: '#fff',
    hoveredInRange: '#c8705c',
  },
  texts: {
    apply: 'Använd',
    cancel: 'Avbryt',
    keyboardShortcuts: 'Kortkommandon',
  },
});

import format from 'date-fns/format'

import moment from 'moment';

var locale = document.documentElement.lang;
moment.locale(locale);

interface IIEDatePickerComputed {
	translation: any;
	showClearDateButton: boolean;
	dateButtonText: any;
	selectedDatesText: any;
}

interface IIEDatePickerMethods {
  
  toggleShowDatepicker(): void;
  hideDatepicker(): void;
  clearDates(): void;
  onStartDateSelected(val: any): void;
  onEndDateSelected(val: any): void;
  onDateRangeChange(): void;
  updateSearchResults(): void;
  updateDatesInSearchInfo(): void;
}

interface IIEDatePickerData {
	showDatepicker: boolean;
	dateFormat: any;
	startDate: any;
	endDate: any;
}

interface IIEDatePickerProps {
	
}

export default Vue.extend<IIEDatePickerData, IIEDatePickerMethods, IIEDatePickerComputed, IIEDatePickerProps>({
	name: 'date-picker',
	data() {
		return {
      showDatepicker: false,
		  dateFormat: 'D MMM YYYY',
		  startDate: '',
		  endDate: ''
		}
	},
	computed: {
        translation() { return this.$store.getters.translation },        
		    showClearDateButton: function(){
            return this.startDate || this.endDate;
        },   
        dateButtonText: function(){
            //Get translation
            let key = "filter_dates_button_text";
            let translation = this.translation[key];
            if(translation){
                return translation;
            }
            return key;
        },          
        selectedDatesText(){
            if(this.startDate) {
                let text = '';
                let startDateText = '';
                let endDateText = '';
                if(this.startDate) {
                    startDateText = moment(this.startDate).format('D MMM');
                }
                if(this.endDate) {                    
                    endDateText = moment(this.endDate).format('D MMM');
                }
                if(startDateText){
                    text += startDateText;
                }
                if(endDateText && endDateText !== startDateText){
                    text += " - " +endDateText;
                }
                return text;
            }            
        },           
	},
	methods: {
        toggleShowDatepicker:function(){
            this.showDatepicker = !this.showDatepicker;
        },        
        hideDatepicker:function(){
            if(this.showDatepicker){
                this.showDatepicker = false;
            }
        },        
        clearDates: function(){
            this.startDate = null;
            this.endDate = null;
            this.hideDatepicker();
            this.updateDatesInSearchInfo();
            this.updateSearchResults();            
        },        
        onStartDateSelected(val){
          this.startDate = val;
          this.onDateRangeChange();        
        },
        onEndDateSelected(val){
          this.endDate = val;
          this.onDateRangeChange();
        },        
        onDateRangeChange() {
          if(this.startDate && this.endDate){
            this.hideDatepicker();
            this.updateDatesInSearchInfo();
            this.updateSearchResults();
          }
        }, 
        updateSearchResults: function(){
            this.$store.commit({
                type: 'setSearchInfo',
                payload: { 
                    currentPage: 1,
                    timestamp: Date.now(),
                }
            });
            this.$router.push({query: this.$store.getters.urlQuery});
        },        
        updateDatesInSearchInfo: function(){
            let startDateString = '';
            let endDateString = '';
            if(this.startDate) {
              startDateString = moment(this.startDate).format('YYYYMMDD');
            }
            if(this.endDate) {
              endDateString = moment(this.endDate).format('YYYYMMDD');
            }             

            this.$store.commit({
                type: 'setFilterDates',
                payload: {
                    date: {
                        start: startDateString,
                        end: endDateString,
                    }
                }
            });

        },                  
    },
    created() {
        //Init dates from state
        if(this.$store.state.filters.dates.startdate){
            this.startDate = this.$store.state.filters.dates.startdate;
            if(this.$store.state.filters.dates.enddate){
              this.endDate = this.$store.state.filters.dates.enddate;            
            }
        }
    },   
})
