<template>    
        <a :href="hit.url" :id="'hit-' +hit.id" v-on:click="onLinkClick" class="jk-event-puff__link jk-events-listing__hit-item jk-event-puff env-m-bottom--medium" :title="'Läs mer om ' +hit.title + ' - ' +hit.dateText">
            
                <div role="img" :aria-label="hit.imageAltText" class="jk-event-puff__image" :style="[hit.imageUrl ? { backgroundImage : 'url(' + encodeURI(hit.imageUrl) + ')' } : {}]"></div>
                <!-- <div class="jk-event-puff__date">
                    <div>{{ hit.dateWithDay }}</div>
                </div> -->
                <div class="env-p-around--xx-small env-h--100 env-d--flex env-flex--column">
                    <!-- <small class="jk-event-puff__location env-text--uppercase env-m-top--small">{{hit.location}}</small> -->
                    <div>
                        <span class="rubrikx1">{{hit.title}}</span>
                    </div>
                    <div class="jk-event-puff__date-time">
                        <span class="jk-event-puff__calendar-icon"></span>{{ hit.dateWithDay }} | <span class="jk-event-puff__time-icon"></span>{{ hit.time }}
                    </div>
                    <div class="jk-event-puff__preamble">
                         {{hit.preamble}}
                    </div>
                    <div class="jk-event-puff__labels">
                        <span class="material-icons-outlined">local_offer</span>
                        <div>{{itemCategoriesText}}</div>
                    </div>
                    <p>Läs mer & köp biljett</p>
                </div>            
        </a>
    
</template>

<script >
import Vue from 'vue';

export default Vue.extend({
    name: "SearchHit",
    props: {
        hit: Object,
        index: Number
    },
    computed: {
        translation() { return this.$store.getters.translation },
        itemCategoriesText() { 
            return this.hit.categories.join (' | ');
        },
    },
    methods: {
        onLinkClick() {
            let query = Object.assign({}, this.$store.getters.urlQuery);
            query.item = this.hit.id;
            this.$router.replace({ query });
        }
    }
});
</script>

