
import Vue from 'vue';


import VDatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment';

var locale = document.documentElement.lang;
moment.locale(locale);
interface IDatePickerComputed {
	translation: any;
	showClearDateButton: boolean;
	dateButtonText: any;
	selectedDatesText: any;
}

interface IDatePickerMethods {
  
  toggleShowDatepicker(): void;
  hideDatepicker(): void;
  clearDates(): void;
  onDateRangeChange(): void;
  updateSearchResults(): void;
  updateDatesInSearchInfo(): void;
}

interface IDatePickerData {
	showDatepicker: boolean;
	range: any;
	modelConfig: any;
}

interface IDatePickerProps {
	
}

export default Vue.extend<IDatePickerData, IDatePickerMethods, IDatePickerComputed, IDatePickerProps>({
	name: 'date-picker',
    components: {
        VDatePicker
    },    
    data: function ()  {
        return {            
            showDatepicker: false,
            range: null,
            modelConfig: {
                start: {
                    type: 'number',
                    mask: 'YYYYMMDD',
                },
                end: {
                    type: 'number',
                    mask: 'YYYYMMDD',
                }
            }          
        }
    },   
	computed: {
        translation() { return this.$store.getters.translation },        
		showClearDateButton: function(){
            return this.range;
        },   
        dateButtonText: function(){
            //Get translation
            let key = "filter_dates_button_text";
            let translation = this.translation[key];
            if(translation){
                return translation;
            }
            return key;
        },          
        selectedDatesText(){
            if(this.range) {
                let text = '';
                let startDateText = '';
                let endDateText = '';
                if(this.range.start) {
                    startDateText = moment(this.range.start).format('D MMM');
                }
                if(this.range.end) {                    
                    endDateText = moment(this.range.end).format('D MMM');
                }
                if(startDateText){
                    text += startDateText;
                }
                if(endDateText && endDateText !== startDateText){
                    text += " - " +endDateText;
                }
                return text;
            }            
        },           
	},
	methods: {
        toggleShowDatepicker:function(){
            this.showDatepicker = !this.showDatepicker;
        },        
        hideDatepicker:function(){
            if(this.showDatepicker){
                this.showDatepicker = false;
            }
        },        
        clearDates: function(){
            this.range = null;
            this.hideDatepicker();
            this.updateDatesInSearchInfo();
            this.updateSearchResults();            
        },        
        onDateRangeChange() {
            this.hideDatepicker();
            this.updateDatesInSearchInfo();
            this.updateSearchResults();
        }, 
        updateSearchResults: function(){
            this.$store.commit({
                type: 'setSearchInfo',
                payload: { 
                    currentPage: 1,
                    timestamp: Date.now(),
                }
            });
            this.$router.push({query: this.$store.getters.urlQuery});
        },        
        updateDatesInSearchInfo: function(){
            let startDateString = '';
            let endDateString = '';
            if(this.range) {
                if(this.range.start) {
                    startDateString = moment(this.range.start).format('YYYYMMDD');
                }
                if(this.range.end) {
                    endDateString = moment(this.range.end).format('YYYYMMDD');
                }
             
            }

            this.$store.commit({
                type: 'setFilterDates',
                payload: {
                    date: {
                        start: startDateString,
                        end: endDateString,
                    }
                }
            });

        },                  
    },
    created() {
        //Init dates from state
        if(this.$store.state.filters.dates.startdate){
            let range = {
                start: this.$store.state.filters.dates.startdate,
                end: this.$store.state.filters.dates.startdate
            };
            if(this.$store.state.filters.dates.enddate){
                range.end = this.$store.state.filters.dates.enddate;
            }
            this.range = range;               
        }
    },    

})
