import { IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as searchService from "../../searchService";

type PayloadType = 'initApp' | 'setState' | 'setStateFromQuery' | 'newSearch'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {

	commit({
		type: 'setState',
		payload
	});

	commit({
		type: 'initFilters',
		payload: payload.filter_options
	});
}

// Action creators
export const setStateFromQuery: ActionCreator<any> = (state) => ({
	type: 'setStateFromQuery',
	payload: state
})

const setStateFromQueryAction: ActionHandler<any> = ({ commit }, { payload }) => {
	var query = payload.query;
	var page = payload.page;
	
	delete payload.page;
	delete payload.query;
	var filters = payload;

	if(query){
		commit({
			type: 'setQuery',
			payload: query
		});
	}
	if(page){
		commit({
			type: 'setCurrentPage',
			payload: parseInt(page)
		});
	}

	if (filters && Object.keys(filters).length > 0 ){
		commit({
			type: 'setFilters',
			payload: filters
		});
	}

	commit({
		type: 'setSearchInfo',
		payload: { 
			timestamp: Date.now()
		},
	});	
}

export const newSearch: ActionCreator<any> = (state) => ({
	type: 'newSearch',
	payload: state
})

const newSearchAction: ActionHandler<any> = ({ commit, state }, { payload }) => {

	commit({
		type: 'setSearching',
		payload: true
	});

	// Make the search
	searchService.search(state.app.submitUrl, state)
		.then((result: any) => {

			var dev = process.env.NODE_ENV === 'development';
			if(dev){
				result = {};
				result.searchInfo = {
					totalPages: 20, 
					totalHits: 196, 
					currentPage: state.searchInfo.currentPage, 
					query: "testing"
				};

				result.searchHits = [];
				for(var i = 1; i < 10; i++) {
					result.searchHits.push(
					{
						id: '359t8u45y8u554' +i ,
						title: i % 2 == 0 ? 'Detta är ett namn på ett evenemang som tar lite plats att skriva ut ' +i : 'Stora evenemanget ' +i,
						//imageUrl: 'https://dummyimage.com/600x400/333333/fff.jpg&text=Evenemang' +i,
						imageUrl: 'https://sivtest1.jonkoping.se/images/18.2c67a555179fa36937b1188/1623934696839/JKPG-25.jpg',
						imageAltText: 'Bild till evenemang ' +i,
						dateText:  i % 2 == 0 ? '3 jun - 15 jun' : '12 aug',
						location: i % 3 == 0? 'Rådhusparken, Jönköping': 'Stadshuset, Jönköping',
						categories: i % 4 != 0? ['Barn & Familj', 'Film', 'Kultur', 'Teater', 'Föreställning', 'Musik'] : ['Konsert'],
						url: 'https://www.hiq.se/'	  
					});
				  }
			}

			if(result.searchInfo.timestamp === state.searchInfo.timestamp || dev){
				commit({
					type: 'setSearchInfo',
					payload: result.searchInfo
				});
				
				commit({
					type: 'setSearchHits',
					payload: result.searchHits
				});

				commit({
					type: 'setSearching',
					payload: false
				});

				//scroll to item
				if(payload && payload.scrollToItem) {
					//Timeout to make sure dom is updated
					setTimeout(function(){
						var itemName = 'hit-' +payload.scrollToItem;
						var item = document.getElementById(itemName);
						if(item) {
							window.scrollTo({
								top: item.offsetTop,
								left: 0,
								behavior: "smooth",
							});
						}
					}, 100);
				}
							
			}	
		})
		.catch(console.error);		
}

const actions: ActionTree<IState, IState> = {
  initApp: initAppAction,
  setStateFromQuery: setStateFromQueryAction,
  newSearch: newSearchAction
};

export default actions;
