<template>
	<div class="" id="jk-events-listing__hit-list env-m-top--medium" aria-live="polite">       
		<div class="jk-events-listing__results">
            <div v-show="!searching || currentPage > 1">
                <div class="jk-events-listing__hit-list">                    
                    <search-hit v-for="hit in hits" :hit="hit" :key="hit.id"/>                  
                </div>
                <p class="normal" v-if="(!hits || hits.length === 0) && !searching">{{translation.zeroSearchResults}}</p>
            </div>
            <div class="env-w--100 env-text-align--center env-m-top--medium">
                <div class="env-spinner" v-show="searching">
                    <div class="env-rect1"></div>
                    <div class="env-rect2"></div>
                    <div class="env-rect3"></div>
                    <div class="env-rect4"></div>
                    <div class="env-rect5"></div>
                </div>                 
            </div>
		</div>        
	</div>
</template>
<script>
import Vue from 'vue';
import SearchHit from './SearchHit';

export default Vue.extend({
	name: 'SearchResults',
    components: {
        SearchHit
    },	
    computed: {
        hits(){
            return this.$store.state.searchHits;
        },
		translation() { return this.$store.getters.translation },
        searching() {
            return this.$store.state.searching;
        },
        currentPage(){
            return this.$store.state.searchInfo.currentPage;
        },        
    },
})
</script>
<style scoped>

</style>
