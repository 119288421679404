// @ts-nocheck

import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from 'vue';
import App from './App.vue';

import makeStore from './store';

import { initApp, setStateFromQuery, newSearch} from './store/actions';
import { IState } from './store/state';
import VueRouter from "vue-router";

Vue.use(VueRouter);
Vue.config.productionTip = false;

Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
	  el.clickOutsideEvent = function (event) {
		// here I check that click was outside the el and his children
		if (!(el == event.target || el.contains(event.target))) {
		  // and if it did, call method provided in attribute value
		  if(vnode && vnode.context && binding.expression && vnode.context[binding.expression]){
		  	vnode.context[binding.expression](event);
		  }
		}
	  };
	  document.body.addEventListener('click', el.clickOutsideEvent)
	  //document.body.addEventListener('touchstart', el.clickOutsideEvent)
	},
	unbind: function (el) {
	  document.body.removeEventListener('click', el.clickOutsideEvent)
	  //document.body.removeEventListener('touchstart', el.clickOutsideEvent)
	},
  });

const createApp = (element: Element, data: IState) => {
	const store = makeStore(); // Make a new store for each instance.
	const router = new VueRouter({
		mode: 'history',
		routes: [
			{ path: '/' },
		]
	});

	return new Vue({
		store,
		router,
		created() {
			this.$store.dispatch(initApp(data));
			if (this.$route.query) {
				let query = Object.assign({}, this.$route.query);
				var scrollToItem = query.item;
				if(query.item) {
					delete query.item;
					this.$router.replace({ query });
				}
				this.$store.dispatch(setStateFromQuery(this.$route.query));
			}			
			this.$store.dispatch(newSearch({scrollToItem:scrollToItem}));

			router.beforeEach((to, from, next) => {				
				if(!to.query.item) {
					this.$store.dispatch(setStateFromQuery(to.query));
					this.$store.dispatch(newSearch());
				}
				next();
			});
		},
		render: h => h(App)
	}).$mount(element);	
};

if (process.env.NODE_ENV === 'production') {
	// REPLACE APP_NAME with a fitting name of your app.
	// Remember to use the same name in your main.js -> onAttached call!!
	(window as any).EVENTSLISTING = (window as any).APP_NAME || createApp;
} else {
	const appElement = document.querySelector('#eventsApp');
	if(appElement) {
		const mock = require('./mock-state').default;
		createApp(appElement, mock);
	}
}