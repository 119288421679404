import { MutationTree, Mutation } from 'vuex';
import { IState, IFilterOption, IInitFilterOption } from './state';
import { ActionPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}
const setSearchInfo: MutationHandler<any> = (state, { payload }) => {
	state.searchInfo = Object.assign({}, state.searchInfo, payload);
}
const setSearchHits: MutationHandler<any> = (state, { payload }) => {
	if(state.searchInfo.currentPage !== undefined && state.searchInfo.currentPage > 1){
		payload.forEach(element => {
			state.searchHits.push(element);
		});		
	} else {
		state.searchHits = payload;
	}
}
const initFilters: MutationHandler<any> = (state, { payload }) => {
	var filterOptions = payload;
	//Object.keys(filterOptions).map(function (key: any, index) {
	Object.keys(filterOptions).forEach(function (key: string) {				
		if (filterOptions[key]) {
			filterOptions[key].forEach((option: IInitFilterOption) => {
				if (state.filters[key]){
					var newFilterOptions: IFilterOption = { id: option.id, title: option.title, value: false }
					state.filters[key].push(newFilterOptions)
				}
			});
		}
	});

	state.filtersConfigured = true;
}


const resetFilters: MutationHandler<any> = (state, { payload }) => {

	for (var key in state.filters) {
		// skip loop if the property is from prototype
		if (!state.filters.hasOwnProperty(key)) continue;

		var filterOptions = state.filters[key];
		for(var i=0; i < filterOptions.length; i++){
			if (filterOptions[i].value) {
				state.filters[key][i].value = false;
			}
		}
	}

}

const setQuery: MutationHandler<any> = (state, { payload }) => {
	state.searchInfo.query = payload;
}

const setCurrentPage: MutationHandler<any> = (state, { payload }) => {
	state.searchInfo.currentPage = payload;
}

const setFilters: MutationHandler<any> = (state, { payload }) => {
	var filters = payload;
	Object.keys(state.filters).forEach(function (key: any) {
	//Object.keys(state.filters).map(function (key: any, index) {
		var trueValues: string[] = [];
		if (filters[key]) {
			trueValues = filters[key].split(',');
		}
		if(key === 'dates'){
			if(filters['start']){
				state.filters[key].startdate =  filters['start'];
			}
			if(filters['end']){
				state.filters[key].enddate = filters['end'];
			}
		} else {
			state.filters[key].forEach(function (filterOption: IFilterOption) {
				if (trueValues.indexOf(filterOption.id) >= 0) {
					filterOption.value = true;
				} else {
					filterOption.value = false;
				}
			});
		}
	});
}

const setFilterDates: MutationHandler<any> = (state, { payload }) => {
	Object.assign(state.filters.dates, {
		startdate: payload.date.start,
		enddate: payload.date.end
	})
}


const setSearching: MutationHandler<any> = (state, { payload }) => {
	state.searching = payload;
}



const mutations: MutationTree<IState> = {
  setState: setStateMutation,
  setSearchInfo: setSearchInfo,
  setSearchHits: setSearchHits,
  //setFilter: setFilter,
  initFilters: initFilters,
  resetFilters: resetFilters,
  setQuery: setQuery,
  setCurrentPage: setCurrentPage,
  setFilters: setFilters,
  setFilterDates: setFilterDates,
  setSearching: setSearching,
};

export default mutations
