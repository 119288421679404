import axios from 'axios';
import queryString from 'query-string';
import { IState, IFilterOption } from '../src/store/state';

const SiteVisionHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
}

interface SearchResponse {
    message: string,
    status: number
}

const makeSearchParams = (state: any) => {
    var searchFilters: any = {};
    for (var key in state.filters) {
    // skip loop if the property is from prototype
        if (!state.filters.hasOwnProperty(key)) continue;

        if(key == 'dates'){
            var dates = state.filters[key];
			if(dates.startdate){
				searchFilters['start'] = dates.startdate;
			}
			if(dates.enddate){
				searchFilters['end'] = dates.enddate;
			}            
        } else {
            var filterGroup = state.filters[key];
            filterGroup.forEach(function (filterOption: IFilterOption) {
                if(filterOption.value){
                    if (!searchFilters[key]){
                        searchFilters[key] = [];
                    }
                    searchFilters[key].push(encodeURIComponent(filterOption.id));
                }
            });            
        }
    }

    var pageMode = 'single';
    var itemsPerPage = 9;
    if(state.searchInfo.currentPage > 1) {
        var shouldBeNumberOfItems = (state.searchInfo.currentPage -1) * itemsPerPage;
        if(shouldBeNumberOfItems >  state.searchHits.length) {
            pageMode = 'all';
        }
    }
    var dataObject = {
        pageMode: pageMode,
        page: state.searchInfo.currentPage,
        query: state.searchInfo.query,
        timestamp: state.searchInfo.timestamp,
        filters: JSON.stringify(searchFilters)
    };

    return dataObject;
}

export const search = async (url: string, state: IState, optionalOverrides: any = ""): Promise<SearchResponse> => {
    const params = Object.assign({}, makeSearchParams(state), optionalOverrides);
    const oldParams = queryString.parse(url);

    const route = `?${queryString.stringify({
        ...oldParams,
        ...params
    })}`;

    return axios.get<SearchResponse>(route, { headers: { ...SiteVisionHeaders } })
        .then((response: any) => response.data)
        .catch((error: any) => {
            console.error({ error });
            return null;
        })

}