<template>
    <div class="env-w--100 env-text-align--center env-m-top--medium" v-show="pageCount > 0 && totalPages > currentPage && !searching">
        <button v-on:click="getMoreResults" type="button" class="jk-events-listing__load-more-button">Visa fler</button>
    </div>
</template>
<script>

export default {
    name: 'Pagination',
    computed: {
        pageCount(){
            return this.$store.getters.searchInfo.totalPages;
        },
        currentPage(){
            return this.$store.state.searchInfo.currentPage;
        },
        totalPages(){
            return this.$store.state.searchInfo.totalPages;
        },
        searching() {
            return this.$store.state.searching;
        },        
    },
    methods: {
        getMoreResults(){
            this.$store.commit({
                type: 'setSearchInfo',
                payload: { 
                    currentPage: this.currentPage +1,
                    timestamp: Date.now(),
                },
            });
            this.$router.push({query: this.$store.getters.urlQuery});
        },
    }
}
</script>