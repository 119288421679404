
import Vue from 'vue';

import SearchForm from './components/SearchForm.vue';
import SearchResults from './components/SearchResults.vue';
import Pagination from './components/Pagination.vue';

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	message: string
}

interface AppProps {

}


export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'eventsApp',
	computed: {
		message(){
			return this.$store.getters.message;
		}
	},
	components: {
		SearchForm,
		SearchResults,
		Pagination
	}
})
